import { render, staticRenderFns } from "./SpaceInsertGenre.vue?vue&type=template&id=0b5a3be0&scoped=true"
import script from "./SpaceInsertGenre.vue?vue&type=script&lang=js"
export * from "./SpaceInsertGenre.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/space/spaceinsert.css?vue&type=style&index=0&id=0b5a3be0&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/space/spacegenre.css?vue&type=style&index=1&id=0b5a3be0&prod&scoped=true&lang=css&external"
import style2 from "@/assets/css/modal/space/playstop.css?vue&type=style&index=2&id=0b5a3be0&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5a3be0",
  null
  
)

export default component.exports